import React from 'react';
import {Fab, Tooltip} from '@mui/material';
import {Edit} from '@mui/icons-material';
import {COLORS, SIZES} from '@fupa/fupa-uikit';
import styled from 'styled-components';
import {useScrollDirection} from 'app/hooks/useScrollDirection';

const EditFab = ({url, secondary}) => {
  const handleEdit = () => {
    window.open(url);
  };

  return (
    <Tooltip title='Bearbeiten' placement='right'>
      <Fab
        sx={{
          color: secondary ? COLORS.darkerGrey : '',
          backgroundColor: secondary ? COLORS.white : '',
          margin: '0.5rem 1rem',
        }}
        onClick={handleEdit}
        color={secondary ? 'default' : 'secondary'}
        size='small'
        aria-label='edit'>
        <Edit />
      </Fab>
    </Tooltip>
  );
};

const Container = styled.div`
  z-index: ${props => props.theme.zIndexFab};
  bottom: ${props => (props.secondary || props.hasMobileSticky ? '8rem' : SIZES[72])};
  right: 0;
  position: fixed;
  display: ${props => (props.forceHide || !props.show ? 'none' : 'flex')};
  align-items: flex-end;
  justify-content: flex-end;
  ${props => props.theme.desktopLayout`
    display: ${props => (props.forceHide ? 'none' : 'flex')};
    bottom: ${props => (props.secondary ? SIZES[72] : SIZES[16])};
  `}
  ${props => props.theme.desktopLayoutWithFab`
    margin-left: ${props => props.theme.desktopContentWidth}px;
    right: unset;
  `};
`;

const EditFabContainer = ({hide, ...props}) => {
  const scrollDirection = useScrollDirection();

  return (
    <Container
      show={scrollDirection === 'up'}
      forceHide={hide}
      secondary={props.secondary}
      hasMobileSticky={props.hasMobileSticky}>
      <EditFab {...props} />
    </Container>
  );
};

export {EditFab, EditFabContainer};
