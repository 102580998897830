import {LinkOnComponent} from 'app/components/links/link';
import React from 'react';
import {generateBasicCompetitionLink} from 'app/helpers/actionsHelpers';

const LinkToCompetition = ({competition, competitionType, subpage, children, ...props}) => {
  const basicUrl = generateBasicCompetitionLink(competition, competitionType);
  const rel = competition.active ? 'follow' : 'nofollow';
  const link = subpage ? `${basicUrl}/${subpage}` : basicUrl;

  return (
    <LinkOnComponent to={link} rel={rel} {...props}>
      {children}
    </LinkOnComponent>
  );
};

export {LinkToCompetition};
