import React from 'react';
import styled from 'styled-components';
import {AdSlot} from 'app/components/ads/AdSlot';

const SCAdSlot = styled(AdSlot)`
  &&& {
    // transform: translate3d(0, 0, 0) fixes flickering issue on iOS
    // https://muffinman.io/blog/ios-safari-scroll-position-fixed/
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    display: inline-block;
    position: fixed;
    height: auto;
    background-color: transparent;
    bottom: calc(4rem + env(safe-area-inset-bottom));
    z-index: 55;
    * {
      transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
    }
    ${props =>
      props.theme.desktopLayout`
      display: none
    `}
  }
`;

const StickyMobileBannerAdSlot = props => {
  return <SCAdSlot {...props} adLayout='mobile' adUnit='Sticky_Mobile_Banner' />;
};

export default StickyMobileBannerAdSlot;
