import {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {createSelector} from 'reselect';

const selectAutoScroll = createSelector(
  state => state.header,
  header => header.autoScroll
);

function useScrollDirection() {
  const prevScrollY = useRef(0);
  const [scrollDown, setScrollDown] = useState(false);
  const autoScroll = useSelector(selectAutoScroll);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (!autoScroll) {
        if (prevScrollY.current < currentScrollY && !scrollDown) {
          setScrollDown(true);
        }
        if (prevScrollY.current > currentScrollY && scrollDown) {
          setScrollDown(false);
        }
      }
      prevScrollY.current = currentScrollY;
    };

    window.addEventListener('scroll', handleScroll, {passive: true});

    return () => window.removeEventListener('scroll', handleScroll);
  }, [autoScroll, scrollDown]);

  return scrollDown ? 'down' : 'up';
}

export {useScrollDirection};
